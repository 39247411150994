import React from "react";
import StoryPage from "../../components/story-page";
/** @jsx jsx */
import { jsx } from "theme-ui";

const ThisPage = ({ location }) => {
  const imgUrl = "/images/people/joanne-mackey-bell.jpeg";
  const fullName = `Joanne Mackey Bell`;
  const zoomUrl =
    "https://us02web.zoom.us/j/89230479891?pwd=NUR4aUxqK3FFbDFBcUJNRWZCdnFvQT09";
  const zoomPhoneNumber = "(647) 374-4685";
  const zoomMeetingId = "892 3047 9891";
  const zoomMeetingPasscode = "701713";
  const birthDate = new Date(1939, 3 - 1, 14);
  const deathDate = new Date(2021, 1 - 1, 16);
  const birthDeathFooter = "";
  const memorialDate = new Date(2021, 2 - 1, 7);
  const memorialTime = "2pm EST";
  const inviteText = `Joanne passed away on January 16th. While we can’t all gather in person at this time, please join us virtually for a service to remember and celebrate Joanne’s life.`;
  const inviteChild = <></>;
  const obituary = `Joanne Mackey Bell was born March 14, 1939 in Louisiana, Missouri to Marion and Helen Prewitt Mackey. She grew up on the family farm near Clarksville, Missouri surrounded by a large, loving family. 
  \nShe was an honor student who especially loved French and playing the flute, graduating from Clopton High School in 1957 and then Stephens College in Columbia, Missouri in 1960. During her college years, she was crowned International Lassie Queen, a competition of the International Shorthorn Association, winning a trip to Scotland where she was feted by Scottish breeders of Shorthorn cattle. 
  \nIn 1960, she married her childhood sweetheart, Olin Nile Bell of Bowling Green, Missouri. A few days after the wedding, they packed the trunk of their car with wedding gifts for the drive to Cambridge, Massachusetts where Nile attended Harvard Law School while Joanne worked at the Harvard Museum of Natural History. She had a fondness for ammonites throughout her life based on this experience. 
  \nThey moved to New York and later to New Jersey where Nile practiced law and Joanne found her true calling, raising their beloved daughter Anne Ashley Bell. 
  \nIn 1979, they relocated to Potomac, Maryland where Joanne spent the rest of her life applying her passion for interior design and landscape design. She spent over thirty years and became a Master in the Japanese art of Ikebana, growing plant materials in her garden for her spectacular arrangements. Her love of art and design never waned, and she studied and enjoyed both regularly. 
  \nShe and Nile traveled extensively, including Europe, Asia, and South America. She particularly enjoyed their numerous opportunities to tour English gardens. 
  \nIn 2005, Joanne began spending her winters in Sarasota, Florida where both her sister, Janet Mackey Thompson and her husband, Ron, and her brother, Frank Mackey and his wife, Helaine, had homes. She reveled in being near her siblings and especially spending holidays with her cherished nieces and nephews, and most particularly with her great nieces and nephews. 
  \nJoanne will be buried in Greenwood Cemetery in Clarksville along with her dear husband and parents who pre-deceased her. In recent years, she has suffered from strokes and heart issues as well as dementia. She has been lovingly cared for at her home by Evelyn Pamanano. Joanne succumbed to a stroke on January 16, 2021 with Evelyn and Ashley by her side.
  \nShe is also survived by her siblings as well as: Her nephew, Chris Smith of Denton, Texas along with his wife Diane and their daughter Elizabeth and her husband Jonah and son Andrew and his wife Sarah. Her niece, Courtney Brown Willard of Pilot Mountain, North Carolina and her husband Ted as well as their son Christopher and daughters Caroline and Jayne. Her nephew Andrew Brown of McAllen, Texas and his wife Jennifer and their son Chase. Her niece Mimi Mackey Hollrah and husband Scot. And, her niece Maria Mackey Timp and husband Andrew and daughter Annie Jaco.`;
  const storiesRecordUrl = "https://stories.afterword.com/fmdaw2dyf";
  const storiesSubmissionsUrl =
    "https://stories.afterword.com/rqt77njrcclpw6dl0ejch4jaq6s1vv6f5plbl9rv";
  const recordingEmbedDirectUrl = [
    {
      url: "https://storage.googleapis.com/afterword-static/recordings/Joanne%20Bell%20%20-%20recording.mp4",
      poster: "/images/people/joanne-intro.png",
      title: "Memorial Recording",
    },
  ];
  const dropboxUrl = "";

  return (
    <StoryPage
      location={location}
      imgUrl={imgUrl}
      fullName={fullName}
      zoomUrl={zoomUrl}
      zoomPhoneNumber={zoomPhoneNumber}
      zoomMeetingId={zoomMeetingId}
      zoomMeetingPasscode={zoomMeetingPasscode}
      optInviteChild={inviteChild}
      customColumnSpacing="1fr 3fr"
      birthDate={birthDate}
      deathDate={deathDate}
      birthDeathFooter={birthDeathFooter}
      memorialDate={memorialDate}
      memorialTime={memorialTime}
      inviteText={inviteText}
      obituary={obituary}
      storiesRecordUrl={storiesRecordUrl}
      storiesSubmissionsUrl={storiesSubmissionsUrl}
      recordingEmbedDirectUrl={recordingEmbedDirectUrl}
      dropboxUrl={dropboxUrl}
    ></StoryPage>
  );
};

export default ThisPage;
